.loading1 {
    &.loading-container {
        background-color: var(--primary-color-2);
        min-height: 100vh;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        .preloader-2 {
            display: flex;
            justify-content: center;
            align-items: center;
        }

        .loading-text {
            color: var(--primary-color-1);
            text-transform: uppercase;
            text-align: center;
        }

        .preloader-2 .line {
            width: .1vw;
            height: 12px;
            background: var(--primary-color-1);
            margin: 0 1px;
            display: inline-block;
            animation: opacity-2 1000ms infinite ease-in-out;
        }

        .preloader-2 .line-1 {
            animation-delay: 800ms;
        }

        .preloader-2 .line-2 {
            animation-delay: 600ms;
        }

        .preloader-2 .line-3 {
            animation-delay: 400ms;
        }

        .preloader-2 .line-4 {
            animation-delay: 200ms;
        }

        .preloader-2 .line-6 {
            animation-delay: 200ms;
        }

        .preloader-2 .line-7 {
            animation-delay: 400ms;
        }

        .preloader-2 .line-8 {
            animation-delay: 600ms;
        }

        .preloader-2 .line-9 {
            animation-delay: 800ms;
        }

        @keyframes opacity-2 {
            0% {
                opacity: 1;
                height: 15px;
            }

            50% {
                opacity: 0;
                height: 12px;
            }

            100% {
                opacity: 1;
                height: 15px;
            }
        }
    }
}

.loading2 {
    .preloader-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 100vh;
        position: relative;
    }

    .preloader-logo {
        width: 50%;
    }

    .preloader {
        width: 50px;
        height: 2px;
        background-color: rgb(240, 240, 240);
        position: relative;
    }

    .preloader::before {
        content: "";
        position: absolute;
        width: 10px;
        height: 10px;
        border-radius: 50%;
        background-color: #999;
        bottom: 20px;
        left: -10px;
        animation: bounce 2s linear infinite;
    }

    .preloader:after {
        content: "";
        position: absolute;
        top: 0;
        left: -2px;
        height: 2px;
        background-color: red;
        animation: color 2s linear infinite;
    }

    .loading-text {
        font-family: 'Poppins', sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 18px;
        color: #012060;
    }

    @keyframes bounce {
        0% {
            bottom: 20px;
            left: -15px;
            width: 10px;
            opacity: 0;
            background: var(--primary-color-1);
        }

        20% {
            bottom: 0px;
            left: 0;
            width: 10px;
            opacity: 1;
            background: var(--primary-color-2);
        }

        22% {
            bottom: 0px;
            left: 0;
            width: 12px;
            height: 8px;
        }

        27% {
            bottom: 0px;
            left: 2px;
            width: 10px;
            height: 10px;
        }

        50% {
            bottom: 20px;
            left: 17px;
            width: 10px;
            height: 10px;
            background: var(--primary-color-1);
        }

        55% {
            bottom: 20px;
            left: 22px;
            width: 10px;
            height: 10px;
        }

        75% {
            bottom: 0px;
            left: 35px;
            width: 10px;
            height: 10px;
            background: var(--primary-color-2);
        }

        80% {
            bottom: 0px;
            left: 35px;
            width: 12px;
            height: 8px;
        }

        82% {
            bottom: 0px;
            left: 35px;
            width: 10px;
            height: 10px;
            opacity: 1;
        }

        100% {
            bottom: 20px;
            left: 50px;
            width: 10px;
            height: 8px;
            opacity: 0;
        }
    }

    @keyframes color {
        0% {
            width: 0;
            background: var(--primary-color-1);
        }

        20% {
            width: 10px;
            background: var(--primary-color-2);
        }

        50% {
            width: 25px;
            background: var(--primary-color-1);
        }

        75% {
            width: 45px;
            background: var(--primary-color-2);
        }

        100% {
            width: 52px;
            background: var(--primary-color-1);
        }
    }
}

.loading3 {
    $bg: #1E212B;
    $fill: var(--primary-color-1);

    .hourglass {
        display: block;
        background: $bg;
        width: 2em;
        height: 4em;
        animation: hourglass 1s linear infinite;
    }

    .outer {
        fill: $fill;
    }

    .middle {
        fill: $bg;
    }

    @keyframes hourglass {
        0% {
            transform: rotate(0deg);
            box-shadow:
                inset $fill 0 -0em 0 0,
                inset $bg 0 -2em 0 0,
                inset $fill 0 -4em 0 0;
        }

        80% {
            transform: rotate(0deg);
            box-shadow:
                inset $fill 0 -2em 0 0,
                inset $bg 0 -2em 0 0,
                inset $fill 0 -2em 0 0;
        }

        100% {
            transform: rotate(180deg);
            box-shadow:
                inset $fill 0 -2em 0 0,
                inset $bg 0 -2em 0 0,
                inset $fill 0 -2em 0 0;
        }
    }
}

.loading4 {
    @keyframes texts {
        0% {
            opacity: 1;
        }

        50% {
            opacity: 0.5;
        }

        100% {
            opacity: 1;
        }
    }

    @keyframes hglass {
        0% {
            box-shadow: 0px 0px 0px #72cfd0 inset;
        }

        25% {
            box-shadow: 0px 0px 0px #72cfd0 inset;
        }

        50% {
            box-shadow: 0px -31px 0px #72cfd0 inset;
        }

        51% {
            box-shadow: 0px -31px 0px #72cfd0 inset;
        }

        75% {
            box-shadow: 0px -31px 0px #72cfd0 inset;
        }

        100% {
            box-shadow: 0px 0px 0px #72cfd0 inset;
        }
    }

    @keyframes hglasstop {
        0% {
            transform: scale(0.5) rotate(0deg);
            box-shadow: 0px 0px 0px #96dbdc inset;
        }

        25% {
            transform: scale(0.5) rotate(180deg);
            box-shadow: 0px 0px 0px #96dbdc inset;
        }

        50% {
            transform: scale(0.5) rotate(180deg);
            box-shadow: 0px 31px 0px #96dbdc inset;
        }

        51% {
            box-shadow: 0px 31px 0px #96dbdc inset;
        }

        75% {
            transform: scale(0.5) rotate(360deg);
            box-shadow: 0px 31px 0px #96dbdc inset;
        }

        100% {
            box-shadow: 0px 0px 0px #96dbdc inset;
        }
    }


    .hourglass {
        transform: scale(0.5);
        -webkit-transform-origin-y: 30px;
        display: block;
        width: 40px;
        height: 30px;
        background: #b9e7e7;
        border-radius: 0px 0px 50px 50px;
        margin: 40px auto 30px;
        position: relative;
        animation: hglasstop 8s infinite;

        .two {
            content: '';
            display: block;
            width: 40px;
            height: 30px;
            background: #b9e7e7;
            border-radius: 50px 50px 0px 0px;
            position: absolute;
            top: 30px;
            animation: hglass 8s infinite;
        }
    }
    .loading-text {
        font-family: 'Poppins', sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 18px;
        color: #ffffff;
    }
}