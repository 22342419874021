:root {
    --primary-color-1: #50C3C5;
    --primary-color-2: #0B0B0D;
    --text-primary: #7A8599;
    --text-seconday: #93969D;
    --text-muted: #999999;
}

body {
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    line-height: unset;
    background-color: #0B0B0D !important;
}

.navigation-backdrop-control {
    z-index: -1;
}

[data-sidebar-style="overlay"] {
    .menu-toggle {
        .navigation-backdrop-control {
            z-index: 1;

            &.d-none {
                z-index: -1;
            }
        }
    }
}

.font-weight-300 {
    font-weight: 300;
}

.font-weight-400 {
    font-weight: 400;
}

.font-weight-500 {
    font-weight: 500;
}

.font-weight-600 {
    font-weight: 600;
}

.font-weight-700 {
    font-weight: 700;
}

.font-weight-800 {
    font-weight: 800;
}

.font-weight-900 {
    font-weight: 900;
}

.font-10 {
    line-height: 105%;

    @media screen and (max-width: 480px) {
        font-size: 2.694vw;
    }

    @media screen and (min-width: 481px) and (max-width: 768px) {
        font-size: 2.194vw;
    }

    @media screen and (min-width: 769px) and (max-width: 1024px) {
        font-size: 1.694vw;
    }

    @media screen and (min-width: 1025px) and (max-width: 1200px) {
        font-size: 1.194vw;
    }

    @media screen and (min-width: 1201px) {
        //xl
        font-size: 0.694vw;
    }
}

.font-12 {
    line-height: 105%;

    @media screen and (max-width: 480px) {
        //xs
        font-size: 2.833vw;
    }

    @media screen and (min-width: 481px) and (max-width: 768px) {
        //sm
        font-size: 2.333vw;
    }

    @media screen and (min-width: 769px) and (max-width: 1024px) {
        //md
        font-size: 1.833vw;
    }

    @media screen and (min-width: 1025px) and (max-width: 1200px) {
        //lg
        font-size: 1.333vw;
    }

    @media screen and (min-width: 1201px) {
        //xl
        font-size: 0.833vw;
    }
}

.font-13 {
    line-height: 105%;

    @media screen and (max-width: 480px) {
        font-size: 2.903vw;
    }

    @media screen and (min-width: 481px) and (max-width: 768px) {
        font-size: 1.903vw;
    }

    @media screen and (min-width: 769px) and (max-width: 1024px) {
        font-size: 1.903vw;
    }

    @media screen and (min-width: 1025px) and (max-width: 1200px) {
        font-size: 1.403vw;
    }

    @media screen and (min-width: 1201px) {
        //xl
        font-size: 0.903vw;
    }
}

.font-14 {
    line-height: 105%;

    @media screen and (max-width: 480px) {
        font-size: 2.972vw;
    }

    @media screen and (min-width: 481px) and (max-width: 768px) {
        font-size: 2.472vw;
    }

    @media screen and (min-width: 769px) and (max-width: 1024px) {
        font-size: 1.972vw;
    }

    @media screen and (min-width: 1025px) and (max-width: 1200px) {
        font-size: 1.472vw;
    }

    @media screen and (min-width: 1201px) {
        font-size: 0.972vw;
    }
}

.font-16 {
    line-height: 105%;

    @media screen and (max-width: 480px) {
        font-size: 3.111vw;
    }

    @media screen and (min-width: 481px) and (max-width: 768px) {
        font-size: 2.611vw;
    }

    @media screen and (min-width: 769px) and (max-width: 1024px) {
        font-size: 2.111vw;
    }

    @media screen and (min-width: 1025px) and (max-width: 1200px) {
        font-size: 1.611vw;
    }

    @media screen and (min-width: 1201px) {
        font-size: 1.111vw;
    }
}

.font-18 {
    line-height: 105%;

    @media screen and (max-width: 480px) {
        font-size: 3.25vw;
    }

    @media screen and (min-width: 481px) and (max-width: 768px) {
        font-size: 2.75vw;
    }

    @media screen and (min-width: 769px) and (max-width: 1024px) {
        font-size: 2.25vw;
    }

    @media screen and (min-width: 1025px) and (max-width: 1200px) {
        font-size: 1.75vw;
    }

    @media screen and (min-width: 1201px) {
        font-size: 1.25vw;
    }
}

.font-20 {
    line-height: 105%;

    @media screen and (max-width: 480px) {
        font-size: 3.389vw;
    }

    @media screen and (min-width: 481px) and (max-width: 768px) {
        font-size: 2.889vw;
    }

    @media screen and (min-width: 769px) and (max-width: 1024px) {
        font-size: 2.389vw;
    }

    @media screen and (min-width: 1025px) and (max-width: 1200px) {
        font-size: 1.889vw;
    }

    @media screen and (min-width: 1201px) {
        font-size: 1.389vw;
    }
}

.font-24 {
    line-height: 105%;

    @media screen and (max-width: 480px) {
        font-size: 3.667vw;
    }

    @media screen and (min-width: 481px) and (max-width: 768px) {
        font-size: 3.167vw;
    }

    @media screen and (min-width: 769px) and (max-width: 1024px) {
        font-size: 2.667vw;
    }

    @media screen and (min-width: 1025px) and (max-width: 1200px) {
        font-size: 2.167vw;
    }

    @media screen and (min-width: 1201px) {
        font-size: 1.667vw;
    }
}

.font-30 {
    line-height: 109%;

    @media screen and (max-width: 480px) {
        font-size: 4.083vw;
    }

    @media screen and (min-width: 481px) and (max-width: 768px) {
        font-size: 3.583vw;
    }

    @media screen and (min-width: 769px) and (max-width: 1024px) {
        font-size: 3.083vw;
    }

    @media screen and (min-width: 1025px) and (max-width: 1200px) {
        font-size: 2.583vw;
    }

    @media screen and (min-width: 1201px) {
        font-size: 2.083vw;
    }
}

.font-32 {
    line-height: 109%;

    @media screen and (max-width: 480px) {
        font-size: 4.222vw;
    }

    @media screen and (min-width: 481px) and (max-width: 768px) {
        font-size: 3.722vw;
    }

    @media screen and (min-width: 769px) and (max-width: 1024px) {
        font-size: 3.222vw;
    }

    @media screen and (min-width: 1025px) and (max-width: 1200px) {
        font-size: 2.722vw;
    }

    @media screen and (min-width: 1201px) {
        font-size: 2.222vw;
    }

}

.font-35 {
    line-height: 109%;

    @media screen and (max-width: 480px) {
        font-size: 3.931vw;
    }

    @media screen and (min-width: 481px) and (max-width: 768px) {
        font-size: 3.431vw;
    }

    @media screen and (min-width: 769px) and (max-width: 1024px) {
        font-size: 2.931vw;
    }

    @media screen and (min-width: 1025px) and (max-width: 1200px) {
        font-size: 2.431vw;
    }

    @media screen and (min-width: 1201px) {
        font-size: 1.823vw;
    }
}

.font-36 {
    line-height: 109%;

    @media screen and (max-width: 480px) {
        font-size: 4.5vw;
    }

    @media screen and (min-width: 481px) and (max-width: 768px) {
        font-size: 4.0vw;
    }

    @media screen and (min-width: 769px) and (max-width: 1024px) {
        font-size: 3.5vw;
    }

    @media screen and (min-width: 1025px) and (max-width: 1200px) {
        font-size: 3.0vw;
    }

    @media screen and (min-width: 1201px) {
        font-size: 2.5vw;
    }
}

.font-40 {
    line-height: 105%;

    @media screen and (max-width: 480px) {
        font-size: 4.778vw;
    }

    @media screen and (min-width: 481px) and (max-width: 768px) {
        font-size: 4.278vw;
    }

    @media screen and (min-width: 769px) and (max-width: 1024px) {
        font-size: 3.778vw;
    }

    @media screen and (min-width: 1025px) and (max-width: 1200px) {
        font-size: 3.278vw;
    }

    @media screen and (min-width: 1201px) {
        font-size: 2.778vw;
    }
}

.opacity-05 {
    opacity: 0.6;
}

.opacity-06 {
    opacity: 0.6;
}

.opacity-07 {
    opacity: 0.7;
}

.opacity-08 {
    opacity: 0.8;
}

.opacity-09 {
    opacity: 0.9;
}


.break-white-space {
    white-space: break-spaces;
}

.primary-text {
    color: var(--primary-color-1);
}

.secondary-text {
    color: var(--text-seconday);
}

a.primary-text {
    &:hover {
        color: #ffffff;
    }
}

a.text-white {
    &:hover {
        color: var(--primary-color-1) !important;
    }
}

.text-black-1 {
    color: var(--text-primary);
}

.text-grey-1 {
    color: var(--text-seconday);
}

.text-grey-2 {
    color: #93969D;
}

.text-blue {
    color: var(--primary-color-2);
}

.text-pink {
    color: var(--primary-color-1);
}

.text-muted {
    color: var(--text-muted);
}

.letter-spacing-03px {
    letter-spacing: 0.3px;
}

.letter-spacing-05px {
    letter-spacing: 0.5px;
}

.letter-spacing-003em {
    letter-spacing: 0.0333333em;
}

.input-group {
    border-radius: 15px;
    overflow: hidden;
    // background: linear-gradient(89.72deg, #292E3C 0.37%, #1E212B 82.9%);
    background: #151414;
    align-items: center !important;
    border: 1px solid transparent;

    &:hover,
    &:focus,
    &:active {
        border: 1.5px solid var(--primary-color-1);
    }

    .icon {
        width: 40%;
    }

    .icon2 {
        width: 50%;
    }
}

.input-transparent {
    border: none !important;
    background-color: transparent !important;
    background: transparent !important;
}

.transparent-bg {
    background: transparent;
}

.requestCodeBtn {
    background: linear-gradient(180.53deg, #8AFBFD 21.56%, #06BDC0 104.92%);
    // background: var(--primary-color-1);
    border: 1px solid var(--primary-color-1);
    border-radius: 5px;
    color: #193A3E !important;

    &:hover,
    &:active,
    &:focus {
        background: linear-gradient(180.53deg, #06BDC0 21.56%, #8AFBFD 104.92%);
        // background-color: rgba(80, 195, 197, 0.8);
        border: 1px solid rgba(80, 195, 197, 0.8);
        box-shadow: 0 0.5em 0.5em -0.3em rgba(80, 195, 197, 0.4);
    }
}

.primary-btn,
.swal-button--confirm {
    background: linear-gradient(180.53deg, #8AFBFD 21.56%, #06BDC0 104.92%);
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
    border-radius: 14px;
    transition: transform 500ms ease-out;
    position: relative;
    cursor: pointer;
    overflow: hidden;
    color: #193A3E !important;
    border: 1px solid var(--primary-color-1) !important;

    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: -100%;
        width: 100%;
        height: 100%;
        background: linear-gradient(90deg, transparent, #fff, transparent);
        transition: 0.5s;
        transform: rotate(-45deg);
    }

    &:hover,
    &:active,
    &:focus {
        background: linear-gradient(180.53deg, #06BDC0 21.56%, #8AFBFD 104.92%);
        box-shadow: 0 0.5em 0.5em -0.4em rgba(80, 195, 197, 0.5) !important;
        transform: translateY(-0.25em);

        &::before {
            left: 100%;
        }
    }
}

.cancel-btn {
    background: #7A8599;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
    // background: #D65900 !important;
    border-radius: 10px;
    transition: transform 500ms ease-out;
    position: relative;
    cursor: pointer;
    overflow: hidden;
    color: #ffffff !important;
    border: 1px solid #7A8599 !important;

    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: -100%;
        width: 100%;
        height: 100%;
        background: linear-gradient(90deg, transparent, #fff, transparent);
        transition: 0.5s;
        transform: rotate(-45deg);
    }

    &:hover,
    &:active,
    &:focus {
        background: #7A8599 !important;
        box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2) !important;
        transform: translateY(-0.25em);

        &::before {
            left: 100%;
        }
    }
}

a {
    &:hover {
        color: var(--primary-color-1);
    }
}

.errorBorder {
    border: 1.5px solid #C10808 !important;
}

.errorTextRed {
    color: #FF6666;
}

.card-document {
    background: linear-gradient(89.72deg, #232835 0.37%, #1E212B 72.42%);
    border-radius: 16px;
}

.row {
    &.display-flex1 {
        display: flex;
        flex-wrap: wrap;

        [class*='display-flex-col1'] {
            flex-grow: 1;
        }
    }
}

.modal-content {
    background: #16171C;
    backdrop-filter: blur(11.5px);
    border-radius: 30px;

    .title-border-bottom {
        background: #50C3C5;
        border-radius: 10px;
        height: 4px;
        width: 5vw;
    }
}

.card-black {
    background: linear-gradient(89.72deg, #292E3C 0.37%, #1E212B 82.9%);
    border-radius: 20px;
}

.back-icon {
    background: rgba(47, 49, 66, 0.6);
    border-radius: 12px;
    transition: all 1s;

    &:hover {
        background: rgba(47, 49, 66, 0.9);
    }
}

.card-green-gradient {
    background: linear-gradient(86.87deg, #116466 1.86%, #25C6C6 94.58%);
    // background: radial-gradient(93.37% 930.01% at 93.37% 0%, #3F8787 0%, rgba(63, 135, 135, 0.442708) 50.63%, rgba(63, 135, 135, 0) 100%), linear-gradient(0deg, rgba(80, 195, 197, 0.15), rgba(80, 195, 197, 0.15)), #0B0B0D;
    border-radius: 20px;
}

.card-transparent-dot {
    border: 1px solid #50C3C5;
    border-radius: 20px;
    background: transparent;
}

.card-black-2 {
    background: #0B141B;
    border-radius: 20px;
}

.status {
    &.new {
        color: var(--text-seconday);
    }

    &.approved {
        color: #418A49;
    }

    &.pending {
        color: #F6A300;
    }

    &.rejected {
        color: #D65900;
    }
}

.w-15 {
    width: 15%;
}

.tab {
    background: linear-gradient(89.72deg, #292E3C 0.37%, #1E212B 82.9%);
    border-radius: 12px;
    color: #ffffff;

    &.active {
        background: #50C3C5;
        color: #193A3E;
    }
}

.table thead th {
    border-bottom: 0.5px solid #7A8599 !important;
}

.table tbody td {
    border-bottom: 0.5px solid #2F3142 !important;
}

.table tbody tr:last-child {
    td {
        border-bottom: none !important;
    }
}

.pagination {
    justify-content: center;
    align-items: center;
}

.pagination a {
    padding: 10px;
    border-right: none;
    border-left: none;
}

.pagination__link--active {
    border-radius: 4px;
    padding: 5px;
    color: #50C3C5 !important;
}

.previous,
.next {
    width: 3rem;
    text-align: center;
}

.progress2 {
    background: #2F3142;
    border-radius: 10px;
    box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.25), 0 1px rgba(255, 255, 255, 0.08);
}

.progress-bar2 {
    position: relative;
    border-radius: 30px;
    background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.3), rgba(255, 255, 255, 0.05));
    transition: 0.4s linear;
    transition-property: width, #50C3C5;

    &.progress-without-text {
        height: .6vw;
    }

    &.progress-with-text {
        height: 1.2vw;
    }

    @media screen and (max-width: 480px) {
        &.progress-with-text {
            height: 5vw;
        }
    }

    @media screen and (min-width: 481px) and (max-width: 768px) {
        &.progress-with-text {
            height: 2vw;
        }
    }
}

.progress-moved .progress-bar2 {
    background-color: #50C3C5;

    &.percent-0 {
        width: 0%;

    }

    &.percent-10 {
        width: 10%;
        -webkit-animation-name: progressAnimation#{10};
        -webkit-animation-duration: 6s;
        animation-name: progressAnimation#{10};
        animation-duration: 6s;
    }

    &.percent-20 {
        width: 10%;
        -webkit-animation-name: progressAnimation#{20};
        -webkit-animation-duration: 6s;
        animation-name: progressAnimation#{20};
        animation-duration: 6s;
    }

    &.percent-30 {
        width: 30%;
        -webkit-animation-name: progressAnimation#{30};
        -webkit-animation-duration: 6s;
        animation-name: progressAnimation#{30};
        animation-duration: 6s;
    }

    &.percent-40 {
        width: 40%;
        -webkit-animation-name: progressAnimation#{40};
        -webkit-animation-duration: 6s;
        animation-name: progressAnimation#{40};
        animation-duration: 6s;
    }

    &.percent-50 {
        width: 50%;
        -webkit-animation-name: progressAnimation#{50};
        -webkit-animation-duration: 6s;
        animation-name: progressAnimation#{50};
        animation-duration: 6s;
    }

    &.percent-60 {
        width: 60%;
        -webkit-animation-name: progressAnimation#{60};
        -webkit-animation-duration: 6s;
        animation-name: progressAnimation#{60};
        animation-duration: 6s;
    }

    &.percent-70 {
        width: 70%;
        -webkit-animation-name: progressAnimation#{70};
        -webkit-animation-duration: 6s;
        animation-name: progressAnimation#{70};
        animation-duration: 6s;
    }

    &.percent-80 {
        width: 80%;
        -webkit-animation-name: progressAnimation#{80};
        -webkit-animation-duration: 6s;
        animation-name: progressAnimation#{80};
        animation-duration: 6s;
    }

    &.percent-90 {
        width: 90%;
        -webkit-animation-name: progressAnimation#{90};
        -webkit-animation-duration: 6s;
        animation-name: progressAnimation#{90};
        animation-duration: 6s;
    }

    &.percent-100 {
        width: 100%;
        -webkit-animation-name: progressAnimation#{100};
        -webkit-animation-duration: 6s;
        animation-name: progressAnimation#{100};
        animation-duration: 6s;
    }
}

.percentage {
    position: absolute;
    left: 3%;
    top: 0;
}


@for $i from 0 through 100 {
    @keyframes progressAnimation#{$i} {
        0% {
            width: 0%;
            background-color: #72cfd0;
        }

        50% {
            width: ($i/2)+"%";
            background-color: #72cfd0;
        }

        100% {
            width: $i+"%";
            background-color: #50C3C5;
        }
    }
}

.header-right .header-profile .nav-link {
    border-left: none !important;
}

.mining-package {
    @media screen and (max-width: 480px) {
        width: 15% !important;
    }
}

.jsgrid-grid-body::-webkit-scrollbar-thumb,
.dataTables_scrollBody::-webkit-scrollbar-thumb,
.table-responsive::-webkit-scrollbar-thumb {
    background: linear-gradient(180deg, #003D42 0%, #0C5256 100%);
}

.jsgrid-grid-body::-webkit-scrollbar,
.dataTables_scrollBody::-webkit-scrollbar,
.table-responsive::-webkit-scrollbar {
    background-color: transparent;
}

.ps .ps__rail-x:hover,
.ps .ps__rail-y:hover,
.ps .ps__rail-x:focus,
.ps .ps__rail-y:focus,
.ps .ps__rail-x.ps--clicking,
.ps .ps__rail-y.ps--clicking {
    background-color: transparent !important;
}

::-webkit-scrollbar {
    width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
    background: linear-gradient(180deg, #003D42 0%, #0C5256 100%);
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: linear-gradient(180deg, #003D42 0%, #0C5256 100%);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: linear-gradient(180deg, #003D42 0%, #0C5256 100%);
}

[data-sidebar-style="mini"] {
    .show {
        .menu-text {
            display: none !important;
        }
    }

    .ai-icon {
        .d-flex {
            width: 100%;
        }
    }
}

@media only screen and (min-width: 48rem) {
    [data-sidebar-style="mini"][data-layout="vertical"] .deznav .metismenu>li:hover>a {
        background: #50C3C5;
        border-radius: 15px;
    }
}

@media only screen and (min-width: 48rem) and (max-width: 74.9375rem) {
    [data-sidebar-style="mini"][data-layout="vertical"] .deznav .metismenu>li:nth-last-child(-n + 1)>ul {
        bottom: auto !important;
    }
}

.card-dark {
    background: #151414;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
    border-radius: 30px;
}


.card-transparent-borderLeft {
    border-left: 0.5px solid #7A8599;
    background: transparent;
}

.card-black-2 {
    background: linear-gradient(119.9deg, #003D42 2.01%, #1B2626 88.31%);
    border-radius: 20px;
}

.qr-card-bg {
    background: linear-gradient(119.9deg, #003D42 2.01%, #1B2626 88.31%);
    border-radius: 20px;
}

.dark-dropdown-bg {
    background: #151414 !important;
}

.missionLevelBg {
    background: linear-gradient(86.87deg, #116466 1.86%, #25C6C6 94.58%);
    border-radius: 20px;
}

.profile-iconContainerBg {
    background-color: #132E2F;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}