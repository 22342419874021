.profile{
    .divider{
        border: 1px solid #50C3C5;
        height: 100%;
        width: 1px;
    }
    .card-black{
        transition: all 1s linear;
        &:hover, &:focus, &:active{
            background: linear-gradient(89.72deg, #232835 0.37%, #1E212B 72.42%);
        }
    }
    .icon-container{
        background: #151414;
        border-radius: 12px;
        .icon{
            width: 2vw;
        }
    }
    .profile-img{
        width: 50%;
        @media screen and (max-width: 480px) {
            width: 25%;
        }
    }
    .rank-img{
        position: absolute;
        right: 30%;
        width: 15%;
        @media screen and (max-width: 480px) {
            right: 70%;
            width: 30%;
        }
    }
    .rank-container{
        background: #2F3142;
        border-radius: 30px;
        width: 40%;
        @media screen and (max-width: 480px) {
            width: 90%;
        }
    }
    .balance-text-container{
        margin-left: 25%;
        height: 100%;
        @media screen and (max-width: 480px) {
            margin-left: 30%;
            height: auto;
        }
    }
    .usdt-icon{
        position: absolute;
        top: -250%;
        width: 20%;
        @media screen and (max-width: 480px) {
            position: relative;
            top: 0;
        }
    }
}
.referralCode{
    .referral-qr-container{
        background: #ffffff;
    }
}

.document{
    min-height: 8vw;
    aspect-ratio: 2;
}

.status-user{
    &.active{
        background: #C6FFC5;
        border-radius: 6px;
        color: #418A49;
        text-align: center;
    }
    &.inactive{
        background: #FFDDC6;
        border-radius: 6px;
        color: #D65900;
        text-align: center;
    }
}

.language{
    .outerDot{
        width: 18px;
        height: 18px;
        border: 1px solid #50C3C5;
        text-align: center;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .innerDot{
        width: 12px;
        height: 12px;
        background: #50C3C5;
        border-radius: 50%;
    }
}

.current-rank-img{
    width: 75%;
    @media screen and (max-width: 480px) {
        width: 40%;
    }
}