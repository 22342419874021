.dividend{
    .icon-container{
        background: #151414;
        border-radius: 12px;
        .icon-dividend{
            width: 80% !important;
        }
        @media screen and (max-width: 480px) {
            .icon-dividend{
                width: 70% !important;
            }
        }
    }
    .rank-logo{
        @media screen and (max-width: 480px) {
            width: 15% !important;
        }
    }
}