.overview {
    .quicklink-icon-container {
        background: rgba(80, 195, 197, 0.1);
        backdrop-filter: blur(5px);
        border-radius: 50%;
        text-align: center;
        width: 60px;
        height: 60px;
        display: flex;
        align-items: center;
        justify-content: center;

        @media screen and (max-width: 480px) {
            width: 40px;
            height: 40px;
        }
    }

    .quicklink-icon {
        width: 60%;

    }

    .quicklink-item {
        &:hover {
            .quicklink-icon-container {
                background: rgba(80, 195, 197, 0.4);
            }
        }
    }
}

.commission {
    .balance-text-container {
        margin-left: 25%;
        height: 100%;

        @media screen and (max-width: 480px) {
            margin-left: 10%;
            height: auto;
        }
    }

    .commission-icon {
        position: absolute;
        top: -50%;
        width: 20%;

        @media screen and (max-width: 480px) {
            position: relative;
            top: 0;
            width: 10%;
        }
    }

    .vertical-line {
        background-color: #909D9F;
        width: 1px;
        height: 20px;
        display: block;
    }
}

// .progress2 {
//     background: #2F3142;
//     border-radius: 10px;
//     box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.25), 0 1px rgba(255, 255, 255, 0.08);
// }
// .progress-bar2 {
//     position: relative;
//     border-radius: 30px;
//     background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.3), rgba(255, 255, 255, 0.05));
//     transition: 0.4s linear;  
//     transition-property: width, #50C3C5; 
//     &.progress-without-text{
//         height: .6vw;
//     }
//     &.progress-with-text{
//         height: 1.2vw;
//     }   
// }

// .progress-moved .progress-bar2 {
//     width: 33%; 
//     background-color: #50C3C5;  
//     animation: progressAnimation 6s;
// }
// .percentage{
//     position: absolute;
//     left: 4%;
// }

// @keyframes progressAnimation {
//     0%   { width: 5%; background-color: #72cfd0;}
//     100% { width: 33%; background-color: #50C3C5; }
// }

.balance-text-container {
    margin-left: 25%;
    height: 100%;

    @media screen and (max-width: 480px) {
        margin-left: 30%;
        height: auto;
    }
}

.usdt-icon {
    position: absolute;
    top: -250%;
    width: 20%;

    @media screen and (max-width: 480px) {
        position: relative;
        top: 0;
    }
}

.recharge {
    .wallet-qr-container {
        background: transparent;
        border-radius: 20px;
    }
}

.withdrawal {
    .withdraw-status {
        text-align: center;
        display: inline-block;

        &.complete {
            background: #C6FFC5;
            border-radius: 6px;
            color: #418A49;
        }

        &.approved {
            background: #C6FFC5;
            border-radius: 6px;
            color: #418A49;
        }

        &.rejected {
            background: #FFDDC6;
            border-radius: 6px;
            color: #D65900;
        }

        &.pending {
            background: #FFFDC8;
            border-radius: 6px;
            color: #F6A300;
        }
    }
}

.banner {
    width: 100%;
    aspect-ratio: 5;
    object-fit: cover;
    border-radius: 10px;
}

.stakeTableImg {
    width: 50px;
}

.stakingTableBg {
    background: linear-gradient(119.9deg, #003D42 2.01%, #1B2626 88.31%);
    border-radius: 20px;
}

.staking-cancel-btn {
    border: 1px solid #50C3C5 !important;
    border-radius: 14px !important;
    color: #50C3C5 !important;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
    transition: transform 500ms ease-out;
    position: relative;
    cursor: pointer;
    overflow: hidden;

    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: -100%;
        width: 100%;
        height: 100%;
        background: linear-gradient(90deg, transparent, #fff, transparent);
        transition: 0.5s;
        transform: rotate(-45deg);
    }

    &:hover,
    &:active,
    &:focus {
        // background: #7A8599 !important;
        box-shadow: 0 0.5em 0.5em -0.4em #50C3C5 !important;
        transform: translateY(-0.25em);

        &::before {
            left: 100%;
        }
    }
}