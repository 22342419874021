.income {
    .settlementDate {
        background: #171920;
        border-radius: 14px;
        color: #d65900;
        text-align: center;
    }
    .balance-text-container{
        margin-left: 25%;
        height: 100%;
        @media screen and (max-width: 480px) {
            margin-left: 30%;
            height: auto;
        }
    }
    .usdt-icon{
        position: absolute;
        top: -250%;
        width: 20%;
        @media screen and (max-width: 480px) {
            position: relative;
            top: 0;
        }
    }
}