.header-logo{
    width: 50%;
}
.header-logo-text{
    width: 20%;
}

.nav-header{
    background: #003D42 !important;
}
.deznav{
    background: linear-gradient(119.9deg, #003D42 4.01%, #1B2626 88.31%);
    // background-color: #16171C !important;
    border-right: 0.5px solid rgba(80, 195, 197, 0.4);
    box-shadow: 0px 10px 30px 5px rgba(8, 31, 34, 0.2);
}
.header{
    background: linear-gradient(180deg, #003D42 0%, #0C5256 100%) !important;
}
.hamburger{
    .line{
        background: var(--primary-color-1) !important;
    }
}
[data-sidebar-style="overlay"]{
    .hamburger{
        .line{
            background: var(--primary-color-2) !important;
        }
    }
}
.menu-toggle{
    .hamburger{
        .line{
            background: var(--primary-color-2) !important;
        }
    }
}

.nav-item{
    cursor: pointer;
    .lang-dropdown {
        background: linear-gradient(89.72deg, #232835 0.37%, #1E212B 72.42%);
        border-radius: 46px;
        color: #ffffff;
    }

    .lang-dropdown-menu-lg {
        right: 0;
        display: none;
        border-radius: 10px;
        margin-top: 8px;
        width: 10vw;
        padding: 10px;
        transform-origin: top left;
        z-index: 9999;
        position: absolute;
        top: 100%;
        overflow: scroll;
        background: linear-gradient(89.72deg, #232835 0.37%, #1E212B 72.42%);
        box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.2);
        backdrop-filter: blur(21px);
        border-radius: 16px;

        @media screen and (max-width: 767px) {
            width: 100%;
        }
    }

    .lang-dropdown-menu-lg span {
        padding: 10px;
        width: 100%;
        box-sizing: border-box;
        text-align: center;
        cursor: pointer;
        transition: background 0.3s ease;
    }

    .lang-menu-container {
        height: auto;
        overflow-y: auto;
        flex-direction: column;
        display: flex;

        @media screen and (max-width: 767px) {
            height: 50%;
        }
    }

    .lang-dropdown-menu-lg span:hover {
        background: var(--primary-color-1);
        color: #ffffff;
        border-radius: 10px;
    }

    #openDropdown-lg:checked+.lang-dropdown-menu-lg {
        display: flex;
        animation: openDropDown 0.4s ease 0s 1 forwards;
        overflow: hidden;
    }

    @keyframes openDropDown {
        from {
            transform: rotateX(50deg);
        }

        to {
            transform: rotateX(0deg);
        }
    }

    .bell{
        width: 25px;
    }

    .profile-img{
        border-radius: 50%;
        width: 3vw;
    }

    &.header-profile{
        .dropdown-menu{
            border-radius: 16px;
            padding: 10px;
            background: linear-gradient(89.72deg, #232835 0.37%, #1E212B 72.42%) !important;
        }
        .dropdown-item{
            &:hover{
                background: var(--primary-color-1);
                color: #ffffff;
                border-radius: 10px;
            }
        }
    }
}

.header-right .notification_dropdown .nav-link{
    background: transparent !important;
}

.header-right .header-profile .nav-link img{
    border-radius: 50% !important;
}