
.ai-icon{
    display: flex !important;
    align-items: center;
}
.icon-active, .icon-inactive{
    width: 1.25rem;
}
.icon-active{
    display: none;
}
.mm-parent-active,.mm-active{
    .icon-active{
        display: inline-block;
    }
    .icon-inactive{
        display: none;
    }
}
.mm-parent-active{
    .mm-collapsed{
        .icon-active{
            display: none;
        }
        .icon-inactive{
            display: inline-block;
        }
    }
}

.bordermenu a:hover, .bordermenu .mm-active{
    background: #11AEA5 !important;
    
    border-radius: 5px;
    padding: 5px;
    span{
        color: #ffffff !important;
    }
}

.metismenu{
    .main-menu{
        .ai-icon{
            .menu-text{
                color: #7A8599;
            }
            &:hover{
                background: #50C3C5;
                border-radius: 15px;
                .menu-text{
                    color: transparent !important;
                    background: linear-gradient(89.72deg, #232835 0.37%, #1E212B 72.42%);
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;
                    background-clip: text;
                    text-fill-color: transparent;
                }
                .icon-active{
                    display: inline-block;
                }
                .icon-inactive{
                    display: none;
                }
            }
        }
        &.mm-active{
            .ai-icon{
                background: #50C3C5;
                border-radius: 15px;
                box-shadow: none;
                .menu-text{
                    background: linear-gradient(89.72deg, #232835 0.37%, #1E212B 72.42%);
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;
                    background-clip: text;
                    text-fill-color: transparent;
                }
                .icon-active{
                    display: inline-block;
                }
                .icon-inactive{
                    display: none;
                }
            }
        }
    }
    .child-menu-text{
        color: #7A8599;
    }
   
    li:hover > a, li:focus > a {
        color: #7A8599 !important;
    }
}
.deznav .metismenu ul li:before {
    content: '';
    height: 0px !important;
    width: 0px !important;
}
.deznav .metismenu ul .menu-inactive a:before {
    position: absolute;
    content: url('../images/menu/angle-right-inactive.svg') !important;
    height: auto !important;
    transition: all 0.5s;
    width: auto !important;
    top: 50%;
    left: 2rem !important;
    border-radius: 0px !important;
    background: transparent !important;
    transform: translateY(-50%);
}
.deznav .metismenu ul .menu-active a:before {
    position: absolute;
    content: url('../images/menu/angle-right-active.svg') !important;
    height: auto !important;
    transition: all 0.5s;
    width: auto !important;
    top: 50%;
    left: 2rem !important;
    border-radius: 0px !important;
    background: transparent !important;
    transform: translateY(-50%);
}
.deznav .metismenu > li.mm-active > a {
    background: #50C3C5 !important;
    border-radius: 15px !important;
    box-shadow: none !important;
    .menu-text{
        background: linear-gradient(89.72deg, #232835 0.37%, #1E212B 72.42%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        text-fill-color: transparent;
    }
}
.deznav .metismenu ul a {
    padding-left: 4rem;
}
.submenu{
    li{
        &:hover, &:focus, &:active{
            a:before{
                left: 2rem !important;
                content: url('../images/menu/angle-right-active.svg') !important;
            }
            .child-menu-text{
                color: #50C3C5;
            }
        }
    }
}
.menu-active{
    .child-menu-text{
        color: #50C3C5;
    }
}
